const status = {'1':'re'}
<template>
	<div class="main" v-loading="loading">
		<div class="tit">
			<span>IP管理</span>
			<div class='new' @click="loginClose">新增IP</div>
		</div>
		<div v-if="list.length > 0">
      <div class="listItems" v-for="item in list" :key="item.iid">
        <div class="logo">
					<img :src="item.logo"/>
				</div>
				<div class="content">
					<div class="baseInfo">
						<div class="left">
							<div class="name">{{item.name}}</div>
							<div class="tag">{{item.type}}</div>
							<div :class="['status',{[statusConfig[item.audit_status]]:true} ]">{{item.status_name}}</div>
							<div class="reason" v-show="item.audit_status === '4'"><img src='@/assets/icon/error/error.png' />{{item.refuse_reason}}</div>
						</div>
						<div class="right">
							<div class="btn" @click="goToDetail(item.id)">预览</div>
							<div class="btn" v-show="item.audit_status === '3'" @click="changeSoldOut(item.id)">下架</div>
							<div class="btn" v-show="item.audit_status === '1' || item.audit_status === '5'" @click="edit(item.id,'online')">上架</div>
							<div class="btn" @click="edit(item.id,'edit')">修改</div>
						</div>
					</div>
					<ul class="tags">
						<li v-for="tag in item.tags" :key="tag">{{tag}}</li>
					</ul>
					<p class="desc">{{item.desc}}</p>
					<div class="other">
						<div class="name">合作内容：<span class="value">{{item.cooperation_type.join("，")}}</span></div>
						<div class="divide"></div>
						<div class="name">价格区间：<span class="value">{{item.cooperation_price_down}} - {{item.cooperation_price_up}}</span></div>
						<div class="divide"></div>
						<div class="name">付款形式：<span class="value">{{item.pay_type}}</span></div>
						<div class="divide"></div>
						<div class="name">物料审核周期：<span class="value">{{item.material_audit_range}}</span></div>
						<div class="divide"></div>
						<div class="name">合作周期：<span class="value">{{item.cooperation_range.join("，")}}</span></div>
					</div>
				</div>
      </div>
    </div>
    <div v-else class="hasntData">暂无数据，谢谢！</div>
	</div>
</template>
<script type="text/javascript">
	import useStore from '@/store/index.js'
	import tracker from '@/common/tracker.js';
	export default {
		data(){
			return {
				loading:false,  
				list:[],
				statusConfig:{3:'success',2:'audit',4:'fail',1:'soldOut',5:'soldOut'}
			}
		},
		methods:{ 
			loginClose(){
				const result = this.store.setIPManageStatus('认证通过后即可新增以及管理IP信息');
				tracker({opType:6})
				if(result){
					window.open('/person/management/add');
				}
			},
			async getData(){
				this.loading = true
				await this.API.ip_list({}, (res) => { 
					const {data} = res
					this.list = data;
				})
				this.loading = false;
			},
			goToDetail(iid){
				tracker({opType:7,ipId:iid})
				window.open(`/detail?iid=${iid}&preview=1`);
			},
			changeSoldOut(id) {
				tracker({opType:9,ipId:id})
        this.$confirm('是否将IP改为已下架?', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '取消',
          type: 'warning',
					closeOnClickModal:false
        }).then(() => {
					this.API.off_shelf({id}, (res) => { 
						this.$message({
							type: 'success',
							message: '操作成功!'
						});
						this.getData();
				})
        });
      },
			edit(id,type){
				const result = this.store.setContract();
				const opType = type === 'edit' ? 10 :8 ;
				tracker({opType,ipId:id})
				if(result || type === 'edit'){
					window.open(`/person/management/add?iid=${id}`);
				}
			}
		},
		created(){
			this.store = useStore();
			this.getData();
		},
	}
</script>
<style lang="less" scoped="" src='./styles.less'>
	
</style>